<template lang="html">
  <div class="Dashboard">
    <dashboard-top>
      <screen-title title="Help"></screen-title>
    </dashboard-top>
    <div class="container-full">
      <div class="section">
        <div class="section__col section__col--60">
          <section-title title="Frequently Asked Questions"></section-title>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed semper neque at nunc pulvinar convallis. Nunc vel augue in mauris scelerisque gravida eu placerat sapien. Etiam turpis est, pulvinar eget libero nec, dignissim interdum sem. Donec luctus, nisi id fermentum lacinia, urna mi gravida arcu, ac posuere velit velit sit amet arcu. Donec nisl sapien, consequat a volutpat quis, finibus varius nibh. Aenean et magna sit amet arcu aliquet condimentum elementum quis dolor. <p>
          <div class="faq-feed">
            <FAQ v-for="index in 6" :key="index"></FAQ>
          </div>
        </div>
        <div class="section__col section__col--40">
          <section-title title="Contact Us"></section-title>
          <ValidationObserver v-slot="{ handleSubmit }" slim>
            <form @submit.prevent="handleSubmit(handleProfileUpdate)" class="Form Form--inline">
              <formly-form :form="form" :model="message" :fields="fields" tag="div" class="Form__group"></formly-form>
              <button class="btn btn--primary">Send your message</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from '@/partials/FAQ'
export default {
  name: "Help",
  components: {FAQ},
  data: function () {
    return {
      form: {},
      message: {
        topic: '',
        message: ''
      },
      fields: [
        {
           key: 'topic',
           type: 'input',
           rules: 'required',
           templateOptions: {
              type: 'text',
              label: 'Topic',
              layout: 'full',
           },
        },
        {
           key: 'message',
           type: 'textarea',
           templateOptions: {
              label: 'Message',
              layout: 'full',
           },
        },
      ]
    }
  }
}
</script>
