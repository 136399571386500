<template lang="html">
  <div class="FAQ">
    <div :class="['FAQ__question', isVisible ? 'FAQ__question--active' : '']" @click="toggleVisibility">
      <span>{{ data.question }}</span>
      <svg-icon icon="add"></svg-icon>
    </div>
    <transition name="slide">
      <div v-if="isVisible">
        <div class="FAQ__answer">
          <p>{{ data.answer }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed semper neque at nunc pulvinar convallis. Nunc vel augue in mauris scelerisque gravida eu placerat sapien. Etiam turpis est, pulvinar eget libero nec, dignissim interdum sem. Donec luctus, nisi id fermentum lacinia, urna mi gravida arcu, ac posuere velit velit sit amet arcu.'
        }
      }
    }
  },
  data: function() {
    return {
      isVisible: false,
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible
    }
  }
}
</script>
